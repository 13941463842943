import { createRouter, createWebHistory } from "vue-router";
// import Dashboard from "@/views/Dashboard.vue";
// import Invest from "@/views/Invest.vue";
import p2p from "@/views/p2p.vue";
// import VirtualReality from "@/views/VirtualReality.vue";
// import Profile from "@/views/Profile.vue";
// import Rtl from "@/views/Rtl.vue";
// import SignIn from "@/views/SignIn.vue";
// import SignUp from "@/views/SignUp.vue";
// import Calc from "@/views/Calc.vue";
// import CalcIntegra from "@/views/Calcintegra.vue";
// import Restore from "@/views/Restore.vue";

const routes = [
  {
    path: "/",
    name: "p2p",
    component: p2p,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
});

export default router;
