<template>
    <div class="card card-top" >
        <div class="card-body ">
            <div class=" text-center ">
              <div class=" logo-header h-100">
                <img
                  src="../../../assets/img/shapes/waves-white.svg"
                  class="position-absolute h-100 w-50 top-0 d-lg-block d-none"
                  alt="waves"
                />
                <div
                  class="position-relative d-flex align-items-center justify-content-center h-100"
                >
                  <img
                    class="w-100 position-relative z-index-2 "
                    style="max-width:400px;"
                    src="../../../assets/img/success.png"
                    alt="rocket"
                  />
                </div>
              </div>
              
            </div>

            <div class="row pt-4 text-center align-items-center justify-content-center">
              <h4 class="header-text"> Успешно! </h4>
              <p class="text-description-start mt-2 ">
                Ваша оплата прошла успешно, в течение часа деньги поступят на ваш счет в Приложении (не более 15 минут после нажатия на кнопку "оплатил")
                
              </p>
              
            </div>
            
        </div>
      </div>
  
    <div class='row mt-3 fixed-btn'>
      <button
        class="btn my-btn-dark text-white w-100"
        @click="finish()"
        type="button"
        >Завершить</button
      >

    </div>
</template>

<style scoped>
.my-btn-dark{
  background: #17191E;
  border-radius: 8px;
  max-width:500px;
}
.step-indicator{
  background-color: #E2E2E2;
  width: 9px;
  height: 9px;
  border-radius:50%;
  padding:0px;
  margin-left: 2.5px;
  margin-right: 2.5px;
}

.active-indicator{
  background-color: black ;
  width: 28px;
  border-radius: 20px;
}
.card-top {
  border-radius: 0px 0px 0 0;
  height:100vh;
}
.logo-header{
   width: 60%;
   height: auto;
   margin-left: auto;
   margin-right: auto;
   margin-top:35px;
   margin-bottom:40px;
}
.text-description-start{
  max-width:77%;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #403F3F;
}
.fixed-btn{
    position: fixed;
    text-align: center;
    justify-content: center;
    bottom: 0;
    width: 100%;
    padding: 20px;
    margin: 0px;
}
.header-text{
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 39px;
    /* identical to box height */

    text-align: center;

    color: #1B1D21;
}

</style>
<script>

import { mapMutations,mapActions } from "vuex";

export default {
  name: "s1",
  components: {
  },
  data() {
    return {
      errors:{},
      data:{},
      loading: false,
      
    };
  },
  created() {
  },
  beforeUnmount() {
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
    ...mapActions(["signup"]),
    next(){
        this.$store.state.step+=1
    },
    finish(){
      location.href = 'https://app.pubhause.com/me'
    }

  },
};
</script>
