<template>

   <!-- lav-greetings -->
   <section class="lav-greetings ">
    <div class="lav-greetings-wrapper">
       <div class="lav-greetings-title">Приветствуем! Вы выбрали оплату через  P2P</div>
       <div class="lav-greetings_block">
          <p class="lav-greetings_block-title">Комиссия за транзакцию</p>
          <p class="lav-greetings_block-text">Комиссия за транзакцию варьируется от 0,8% до 1,5% от суммы платежа</p>
       </div>
       <div class="lav-greetings_block">
          <p class="lav-greetings_block-title">Время выполнения транзакции</p>
          <p class="lav-greetings_block-text">После перехода к оплате у вас будет 15 минут на выполнение перевода по предоставленным реквизитам. 
            Это необходимо для обеспечения безопасности и предотвращения потери средств.

          </p>
       </div>
       <div class="lav-greetings_block">
          <p class="lav-greetings_block-title">Безопасность операции</p>
          <p class="lav-greetings_block-text">Ваша сделка полностью защищена! После подтверждения вашего платежа средства будут зачислены на ваш личный счет в течение 15 минут. Не забудьте нажать кнопку “Оплатил” после завершения.</p>
       </div>
       <div @click="next" class="cursor-pointer lav-replenishment-btn">Далее</div>
    </div>
 </section> <!-- lav-greetings END -->

</template>

<script>

import { mapMutations,mapActions } from "vuex";

export default {
  name: "s1",
  components: {
  },
  data() {
    return {
      errors:{},
      data:{},
      loading: false,
      
    };
  },
  created() {
  },

  unmounted() {
      this.root.style.setProperty("--bs-body-bg", "#fff");
    },
  mounted() {
      this.root = document.documentElement;
      this.root.style.setProperty("--bs-body-bg", "#0C0C0C");
    },


  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
    ...mapActions(["signup"]),
    next(){
        this.$store.state.step+=3
    }

  },
};
</script>


<style scoped>
  @import '../../../assets/styles/main.css';
</style>
