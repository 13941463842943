<template>
    <router-view />
</template>

<style>
.btn{
  min-height: 40px;
}
/* 400 */
@font-face {
  font-family: 'FactorA-Regular';
  src: url('./assets/styles/fonts/TRIALFactorA-Ultralight100-BF6476bc2f6f4cb.otf') format('woff2')
}

/* 400 */
@font-face {
  font-family: 'FactorA-Regular-narrow';
  src: url('./assets/styles/fonts/FactorA-Regular.woff2') format('woff2')
}

/* 500 */
@font-face {
  font-family: 'FactorA-Medium';
  src: url('./assets/styles/fonts/TRIALFactorA-Medium100-BF6476bc2f5b25b.otf') format('woff2')
}

/* 500 */
@font-face {
  font-family: 'FactorA-Medium-narrow';
  src: url('./assets/styles/fonts/FactorAMedium-Regular.woff2') format('woff2')
}

/* 700 */
@font-face {
  font-family: 'FactorA-Bold';
  src: url('./assets/styles/fonts/TRIALFactorA-Bold100-BF6476bc2f46878.otf') format('woff2')
}

/* 700 */
@font-face {
  font-family: 'FactorA-Bold-narrow';
  src: url('./assets/styles/fonts/FactorA-Bold.woff2') format('woff2')
}

</style>
<script>
import { mapMutations, mapGetters, mapActions } from "vuex";
export default {
  name: "App",
  components: {
  },
  methods: {
    ...mapMutations([
    "toggleConfigurator",
    "toggleWithdraw", 
    "navbarMinimize",
    "toggleTopUpCrypto",
    "toggleReinvest"]),
    ...mapActions(["getUserPlot"]),
  },
  watch:{
      
  },
  computed: {
    ...mapGetters(['authenticated','user']),
    navClasses() {
      return {
        "position-sticky blur shadow-blur mt-4 left-auto top-1 z-index-sticky": this
          .$store.state.isNavFixed,
        "position-absolute px-4 mx-0 w-100 z-index-2": this.$store.state
          .isAbsolute,
        "px-0 mx-4": !this.$store.state.isAbsolute,
      };
    },
  },
  beforeMount() {
    this.$store.state.isTransparent = "bg-transparent";
    if (localStorage.getItem('token')){
        if (localStorage.getItem('token').length > 5) this.$store.state.authenticated = true
    }
  },
};
</script>

<style>

/* 400 */
@font-face {
    font-family: 'FactorA-Regular';
   src: url('./assets/fonts/TRIALFactorA-Ultralight100-BF6476bc2f6f4cb.otf') format('woff2')
}

/* 400 */
@font-face {
   font-family: 'FactorA-Regular-narrow';
   src: url('./assets/fonts/FactorA-Regular.woff2') format('woff2')
}

/* 500 */
@font-face {
   font-family: 'FactorA-Medium';
   src: url('./assets/fonts/TRIALFactorA-Medium100-BF6476bc2f5b25b.otf') format('woff2')
}

/* 500 */
@font-face {
   font-family: 'FactorA-Medium-narrow';
   src: url('./assets/fonts/FactorAMedium-Regular.woff2') format('woff2')
}

/* 700 */
@font-face {
   font-family: 'FactorA-Bold';
   src: url('./assets/fonts/TRIALFactorA-Bold100-BF6476bc2f46878.otf') format('woff2')
}

/* 700 */
@font-face {
   font-family: 'FactorA-Bold-narrow';
   src: url('./assets/fonts/FactorA-Bold.woff2') format('woff2')
}
</style>